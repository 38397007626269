<template>
  <div>
    <div
      class="bg-white px-2 pb-2 border-form"
      :class="hasStepGuide ? 'radius-content' : 'pt-lg-2 py-2'"
    >
      <b-row class="align-items-end">
        <b-col
          :xl="hasStepGuide ? 8 : 6"
          :md="hasStepGuide ? 12 : 6"
          xs="12"
        >
          <label class="font-14 mrb-10">{{ $t('guidePopUp.guideTopUpUSDT.textSelectCoin') }}</label>
          <v-select
            v-model="coinType"
            class="coin-select select-info"
            label="name"
            :options="coinTypes"
            :clearable="false"
            @input="handleChangeCoin"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </span>
            </template>

            <template #selected-option="{ icon, name, value }">
              <img
                class="coin-spacing"
                alt="coin"
                :src="getBrandIcon(`${icon}`)"
              >
              <strong class="coin-spacing">{{ value }}</strong><span class="font-size-option">{{ name }}</span>
            </template>

            <template #option="{ icon, name, value }">
              <img
                class="coin-spacing"
                alt="coin"
                :src="getBrandIcon(`${icon}`)"
              >
              <strong class="coin-spacing">{{ value }}</strong><span class="font-size-option">{{ name }}</span>
            </template>
          </v-select>
        </b-col>

        <b-col
          :xl="hasStepGuide ? 8 : 6"
          :md="hasStepGuide ? 12 : 6"
          xs="12"
          :class="hasStepGuide ? 'mt-1' : 'mt-1 mt-md-0'"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label class="font-14 mrb-10">{{ $t('payment.USDT.labelTransferNetwork') }}</label>
            <feather-icon
              v-if="false"
              v-b-tooltip.v-light="'Something here'"
              icon="HelpCircleIcon"
              size="12"
              class="icon-info"
            />
          </div>

          <v-select
            v-model="transferNetwork"
            class="transfer-option"
            label="transfer"
            :options="transferNetworks"
            :clearable="false"
            @input="handleChangeTransferNetwork"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </span>
            </template>

            <template #selected-option="{ name, value }">
              <strong class="coin-spacing select-info">{{ value }}</strong><span class="font-size-option">{{ name }}</span>
            </template>

            <template #option="{ name, value }">
              <strong class="coin-spacing">{{ value }}</strong><span class="font-size-option">{{ name }}</span>
            </template>
          </v-select>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import paymentMixin from '@/mixins/paymentMixin'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [paymentMixin, envMixin, stepGuideMixin],

  props: {
    method: {
      type: Object,
      default: () => {},
      required: true,
    },

    transfer: {
      type: Object,
      default: () => {},
      required: true,
    },

    coinTypes: {
      type: Array,
      default: () => [],
      required: true,
    },

    transferNetworks: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      coinType: this.method,
      transferNetwork: this.transfer,
    }
  },

  methods: {
    handleChangeCoin(value) {
      this.$emit('on-change', value)
    },

    handleChangeTransferNetwork(value) {
      this.$emit('on-change-transfer', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.coin-spacing {
  margin-right: 5px;
}

.select-info {
  &:hover {
    background-color: #fff;
  }
}

.transfer-option {
  .vs__search {
    padding: 0px;
  }

  .vs__selected {
    padding: 0px;
  }
}
</style>

<style lang="scss" scoped>
.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base) !important;
  padding-top: 1rem;
}

.border-form{
  border-radius: var(--border-radius-base);
}

.coin-select{
  width: 100%;
}

.font-size-option {
  @media(max-width:767px) {
    font-size: 14px;
  }
}
</style>
