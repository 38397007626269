<template>
  <div class="summary-container">
    <h4
      id="text-summary-paypal"
      data-testid="text-summary-paypal"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        class="font-14 text-placeholder"
        data-testid="paypal-summary-amount-text"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount"
        data-testid="paypal-summary-amount-value"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        class="font-14 text-placeholder"
        data-testid="paypal-summary-method-text"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div>
        <img
          :src="require('@/assets/images/pages/payment/ic-paypal.svg')"
          height="24px"
          alt="credit"
          style="margin-right: 5px"
        >
        <strong
          id="paypal-method"
          class="font-14 text-primary"
          data-testid="paypal-summary-method-value"
        >
          PayPal
        </strong>
      </div>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        class="font-14 text-placeholder"
        data-testid="paypal-summary-service-fee-text"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-paypal-fee"
          data-testid="percent-service-paypal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="service-charge-paypal"
          data-testid="service-charge-paypal"
          class="d-flex align-items-center"
        >
          <strong
            class="text-primary"
            data-testid="paypal-summary-service-fee-value"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        class="font-14 text-placeholder"
        data-testid="paypal-summary-method-fee-text"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment: 'Paypal'}) }}
        <small
          id="percent-paypal-fee"
          data-testid="percent-paypal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-paypal-fee"
          data-testid="summary-paypal-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-summary-paypal-fee"
            class="text-primary"
            data-testid="paypal-summary-method-fee-value"
          >{{ paypalFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        class="font-14 text-placeholder"
        data-testid="paypal-summary-tax-fee-text"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-paypal-fee"
          data-testid="percent-tax-paypal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-tax-fee"
          data-testid="summary-tax-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="tax-fee"
            class="text-primary"
            data-testid="paypal-summary-tax-fee-value"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId"
      id="title-tip-fee-paypal"
      data-testid="title-tip-fee-paypal"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="font-14 text-placeholder">
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-paypal-fee"
          data-testid="percent-tip-paypal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="summary-tip-amount"
          data-testid="summary-tip-amount"
          class="d-flex align-items-center"
        >
          <strong
            id="summary-current-tip-amount"
            data-testid="summary-current-tip-amount"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between">
      <div
        class="font-24 text-placeholder"
        data-testid="paypal-summary-total-amount-text"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="total-amount"
        class="font-24 total-amount"
        data-testid="paypal-summary-total-amount-value"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

// Mixins
import paymentMixin from '@/mixins/paymentMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, toastification, numberFormatMixin, generalConfigsMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['loading']),
    ...mapGettersAuth(['user']),

    percentFee() {
      return this.user?.data?.plans?.transferFee?.paypal || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.paypalGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    paypalFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>
<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
  .hr-custom{
    border-top: 1px solid #EEEEEE;
  }

  .total-amount{
    color: #002f83;
  }
</style>
