import { render, staticRenderFns } from "./TopUpAmount.vue?vue&type=template&id=5bb376c2&scoped=true&"
import script from "./TopUpAmount.vue?vue&type=script&lang=js&"
export * from "./TopUpAmount.vue?vue&type=script&lang=js&"
import style0 from "./TopUpAmount.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TopUpAmount.vue?vue&type=style&index=1&id=5bb376c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb376c2",
  null
  
)

export default component.exports