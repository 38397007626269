<template>
  <div class="mb-2">
    <div
      v-if="detailInfo"
      :class=" hasStepGuide ? 'in-process-step-guide' : 'top-up-in-process'"
    >
      <top-up-success
        class="summary-form"
        :detail-info="detailInfo"
        :amount="detailInfo.amount"
      />
    </div>
    <b-row
      v-else-if="!showConfirmation"
      class="width-content add-fund-container mb-2"
    >
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="p-0 card-content"
      >
        <div
          class="payoneer-top-up-amount p-0"
          :class="hasStepGuide ? 'mt-0' : 'mt-2'"
        >
          <!-- :is-toggle="isToggle" -->
          <top-up-amount
            :amount="amount"
            @on-confirm="handleShowConfirm"
          />
        </div>
      </b-col>
    </b-row>

    <b-row
      v-else
      class="width-content add-fund-container"
    >
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="card-content p-0"
      >
        <div
          class="payoneer-card-confirm-amount p-0"
          :class="hasStepGuide ? 'mt-0' : 'mt-2'"
        >
          <!-- :is-toggle="isToggle" -->
          <confirm-amount
            :transaction-info="transactionInfo"
            :amount="amount"
            :billing="billing"
            :wallet="wallet"
            @on-prev="handlePrev"
            @handleShowSuccess="handleShowSuccess"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import TopUpAmount from '@/views/payment/components/PayoneerCard/TopUpAmount.vue'
import TopUpSuccess from '@/views/payment/components/PayoneerCard/TopUpSuccess.vue'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { PAYMENT_METHOD } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import ConfirmAmount from '@/views/payment/components/PayoneerCard/ConfirmAmount.vue'
import paymentMixin from '@/mixins/paymentMixin'

const { mapGetters } = createNamespacedHelpers('payment')
export default {
  components: {
    BRow,
    BCol,
    TopUpAmount,
    TopUpSuccess,
    ConfirmAmount,
  },

  mixins: [envMixin, stepGuideMixin, paymentMixin],

  data() {
    return {
      wallet: {},
      detailInfo: null,
      showConfirmation: false,
      amount: 0,
      billing: [],
      transactionInfo: '',

    }
  },

  computed: {
    ...mapGetters(['listTicket']),

    payoneerTicketPaymentInfo() {
      return this.paymentInfo.payoneerTicket
    },

    payoneerTicketWallet() {
      return this.payoneerTicketPaymentInfo?.info?.map(item => ({
        add: item.to,
      }))
    },
  },

  watch: {
    listTicket: {
      handler(val) {
        if (val) {
          const itemDetail = val?.content?.find(item => item?.details?.paymentMethod === PAYMENT_METHOD.PAYONEER_TICKET && item.status === 'opening')
          this.detailInfo = itemDetail?.details
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.setTransferNetwork()
  },

  methods: {
    setTransferNetwork() {
      // eslint-disable-next-line prefer-destructuring
      this.wallet = this.payoneerTicketWallet[0]
    },

    handleShowConfirm(data) {
      this.showConfirmation = true
      this.amount = data.amount
    },

    handlePrev(data) {
      this.showConfirmation = false
      this.amount = data.amount
      this.transactionInfo = data.transactionId
      this.billing = data.billing
    },

    handleShowSuccess(data) {
      this.isShowSuccessPage = data
      this.$emit('handleShowSuccessTabs', this.isShowSuccessPage)
    },
  },

}
</script>
