<template>
  <div>
    <PayFastForm />

    <top-up-success-modal
      ref="open-top-up-success-modal"
    />

    <top-up-fail-modal
      ref="open-top-up-fail-modal"
      :error-message="$route.query.message"
    />

    <b-row
      v-if="false"
      class="width-warning"
    >
      <b-col
        md="10"
        offset-md="1"
        class="mt-1"
      >
        <div class="warning-message p-2 border-form d-flex align-items-start">
          <img
            class="alert-img mr-50"
            alt="alert"
            :src="require('@/assets/images/common/ic-alert.svg')"
          >
          <span>{{ $t('payment.wiseTransfer.notificationMaintenance', {payment: "PayFast"}) }}</span>
        </div>
        <user-hint :class="hasStepGuide ? 'd-lg-none' : ''" />
      </b-col>
    </b-row>

    <div
      v-else-if="isInProgress"
      :class=" hasStepGuide ? 'in-process-step-guide' : null"
    >
      <Progress :is-in-progress="isInProgress" />
    </div>
    <div
      v-else
      class="width-content"
    >
      <b-row class="add-fund-container">
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="card-content"
        >
          <div
            class="payfast-top-up-amount"
            :class="hasStepGuide ? 'radius-content' : 'list-card'"
            @mouseover="showGuideDefault = false"
            @mouseleave="showGuideDefault = true"
          >
            <top-up-amount
              :payment-method="paymentMethod"
              :amount="amount"
              :loading-button-continue="payFastLoading"
              @on-confirm="handleRequestPayFast"
            />
          </div>
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { BRow, BCol } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import PayFastForm from '@/views/payment/components/PayFast/PayFastForm.vue'
import TopUpFailModal from '@/views/payment/components/LocalBank/TopUpFailModal.vue'
import TopUpSuccessModal from '@/views/payment/components/LocalBank/TopUpSuccessModal.vue'
import TopUpAmount from './TopUpAmount.vue'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('payment')

export default {
  name: 'PayFastCard',
  components: {
    TopUpSuccessModal,
    TopUpFailModal,
    PayFastForm,
    BRow,
    BCol,
    TopUpAmount,
    UserHint,
  },
  mixins: [toastification, stepGuideMixin, authMixin],
  data() {
    return {
      paymentMethod: 'payfast',
      amount: 0,
      showGuideDefault: true,
      isInProgress: false,
      isConfirm: false,
      payFastLoading: false,
    }
  },

  computed: {
    ...mapGetters(['status', 'message', 'payFast']),
  },

  created() {
    this.handleAlert()
  },

  methods: {
    ...mapActions(['requestPayFastPayment']),
    handleAlert() {
      const routeQuery = this.$route.query

      if (routeQuery.status === 'done') {
        this.$nextTick(() => {
          const query = { ...routeQuery }
          this.$refs['open-top-up-success-modal'].showModal()
          delete query.status
          delete query.message
          this.$router.replace({ query })
        })
      }

      if (routeQuery.status === 'rejected') {
        this.$nextTick(() => {
          const query = { ...routeQuery }
          this.$refs['open-top-up-fail-modal'].showModal()
          delete query.status
          this.$router.replace({ query })
        })
      }
    },
    async handleRequestPayFast({ amount }) {
      this.payFastLoading = true
      await this.requestPayFastPayment({ amount })

      if (this.status) {
        const payFastForm = document.getElementById('PayFast_payment_form')
        if (payFastForm) {
          payFastForm.elements.TOKEN.value = this.payFast.token
          payFastForm.elements.ORDER_DATE.value = this.payFast.generatedPayfastTransactionAt
          payFastForm.elements.TXNAMT.value = this.payFast.pkrAmount
          payFastForm.elements.BASKET_ID.value = this.payFast.basketId
          payFastForm.elements.MERCHANT_ID.value = this.payFast.merchantId
          payFastForm.action = this.payFast.submitUrl
          payFastForm.elements.MERCHANT_USERAGENT.value = navigator.userAgent
          payFastForm.elements.CHECKOUT_URL.value = this.payFast.checkoutUrl
          payFastForm.elements.SUCCESS_URL.value = this.payFast.checkoutUrl
          payFastForm.elements.FAILURE_URL.value = this.payFast.checkoutUrl

          payFastForm.submit()
        }
      } else {
        this.payFastLoading = false
        this.toastFailure(this.message)
      }
    },

    goToSupport() {
      if (window.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.note-content{
  font-size: 12px;
  font-style: italic;
}

.list-card{
  // max-width: 864px;
  border-radius: var(--border-radius-base);
}

.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
}

.in-process-step-guide{
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 0;

  .summary-form{
    max-width: 410px;
    margin: 0 auto;
  }
}

.warning-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.payfast-top-up-amount:hover .tooltip-payfast-top-up-amount {
  @media (min-width: 768px) {
    display: block;
  }
}
</style>
